<template>
  <div id="user-profile">
    <b-row class="rounded-lg p-2">
      <b-col cols="12">
        <div class="d-flex flex-row justify-content-between">
          <h3>Basic Information</h3>
          <b-link
            v-if="mode === 'show' && $can('update', 'Super Admin')"
            :to="`/edit-user/${$route.params.id}`"
            class="btn edit-btn"
          >
            Edit <img src="@/assets/images/icons/edit-3.png" alt="img"
          /></b-link>
        </div>
        <hr />
      </b-col>
      <b-col cols="12">
        <validation-observer ref="simpleRules">
          <form>
            <b-row>
              <b-col cols="12" md="6">
                <label for="">
                  First Name <span class="text-danger">*</span></label
                >
                <b-form-group
                  invalid-feedback="contact number field is required"
                  :state="fnameState"
                  label-for="fname"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="First Name"
                    rules="required"
                  >
                    <b-form-input
                      v-model="user.first_name"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Enter First Name"
                      :readonly="mode === 'show'"
                      class="data-text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <label for="">
                  Last Name <span class="text-danger">*</span></label
                >
                <b-form-group
                  invalid-feedback="contact number field is required"
                  :state="lnameState"
                  label-for="fname"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Last Name"
                    rules="required"
                  >
                    <b-form-input
                      v-model="user.last_name"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Enter Last Name"
                      :readonly="mode === 'show'"
                      class="data-text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <label for="">
                  Contact Number <span class="text-danger">*</span></label
                >
                <b-form-group
                  invalid-feedback="contact number field is required"
                  :state="contactState"
                  label-for="fname"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required|integer"
                    name="Contact Number"
                  >
                    <b-form-input
                      v-model="user.phone"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Enter Contact Number"
                      type="number"
                      class="data-text"
                      :readonly="mode === 'show'"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <label for="Last Name"
                  >Email Address <span class="text-danger">*</span></label
                >
                <b-form-group
                  invalid-feedback="Email Address is required"
                  :state="mailState"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      v-model="user.email"
                      :state="errors.length > 0 ? false : null"
                      type="email"
                      placeholder="Enter Email Address"
                      class="data-text"
                      :readonly="mode === 'show'"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" class="text-right">
                <button
                  @click.prevent="handleSubmit"
                  v-if="mode === 'edit' && $can('update', 'Super Admin')"
                  class="btn edit-btn"
                >
                  Update
                </button>
                <button
                  @click.prevent="handleSubmit"
                  v-if="mode === 'create' && $can('create', 'Super Admin')"
                  class="btn edit-btn"
                >
                  Create
                </button>
              </b-col>
            </b-row>
          </form>
        </validation-observer>
      </b-col>
    </b-row>
    <hr />
    <validation-observer ref="passwordRules">
      <form v-if="logedInUser.id === user.id">
        <b-row class="p-2" :hidden="mode === 'show' || mode === 'create'">
          <b-col cols="12" md="6">
            <b-form-group
              invalid-feedback="Old Password Is required"
            >
              <label for="">
                Existing Password
                <span class="text-danger">*</span></label
              >
              <validation-provider
                #default="{ errors }"
                name="Old Password"
                vid="OldPassword"
                rules="required|min:8"
              >
                <b-form-input
                  id="a-password"
                  v-model="password.old_password"
                  :state="errors.length > 0 ? false : null"
                  type="password"
                  placeholder="Type your old password as condition"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <label for="">
              New Password <span class="text-danger">*</span></label
            >
            <b-form-group invalid-feedback="New Password Is required">
              <validation-provider
                #default="{ errors }"
                name="New Password"
                vid="NewPassword"
                rules="required|min:8"
              >
                <b-form-input
                  id="ac-password"
                  v-model="password.password"
                  :state="errors.length > 0 ? false : null"
                  type="password"
                  placeholder="Type your new password as condition"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <label for="">
              Re-Type New Password
              <span class="text-danger">*</span></label
            >
            <b-form-group invalid-feedback="Re-Type Password Is Required">
              <validation-provider
                #default="{ errors }"
                name="Re-Type New Password"
                vid="ReTypePassword"
                rules="required|min:8"
              >
                <b-form-input
                  id="ar-password"
                  v-model="password.password_confirmation"
                  :state="errors.length > 0 ? false : null"
                  type="password"
                  placeholder="Re-Type your new password as condition"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="text-right">
            <button
              @click.prevent="updateUserPassword"
              v-if="mode === 'edit' && $can('update', 'Client')"
              class="btn edit-btn"
            >
              Update Password
            </button>
          </b-col>
        </b-row>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import { getLogedInUser } from "@/utils/auth";
import { toast } from "@/utils/toast";
import { email, required } from "@validations";
import {
BButton,
BCard,
BCol,
BForm,
BFormGroup,
BFormInput,
BLink,
BRow,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  components: {
    BFormGroup,
    BFormInput,
    BRow,
    BRow,
    BCol,
    BForm,
    BButton,
    BCard,
    BLink,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    mode: {
      type: String,
      default: "show",
    },
  },
  data() {
    return {
      contactState: null,
      mailState: null,
      fnameState: null,
      lnameState: null,
      logedInUser: JSON.parse(getLogedInUser()),
      password: {
        password: "",
        password_confirmation: "",
      },
      required,
      email,
    };
  },
  methods: {
    handleSubmit() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          // eslint-disable-next-line
          if (this.mode === "edit") {
            this.updateUser();
          } else {
            this.createUser();
          }
        } else {
          toast(
            "Validation Error",
            "AlertTriangleIcon",
            "danger",
            "Please fill all the required fields"
          );
        }
      });
    },
    createUser() {
      this.$store
        .dispatch("user/createUser", this.user)
        .then((response) => {
          if (response.status_code === 200) {
            toast(
              "Success",
              "CheckCircleIcon",
              "success",
              "User Created Successfully"
            );
            this.$router.push({ name: "Super Admin List" });
          }
        })
        .catch((error) => {
          toast("Error", "AlertTriangleIcon", "danger", "Error Creating User");
        });
    },
    updateUser() {
      this.$store
        .dispatch("user/updateUser", this.user)
        .then((response) => {
          if (response.status_code === 200) {
            toast(
              "Success",
              "CheckCircleIcon",
              "success",
              "User Updated Successfully"
            );
            this.$router.push({ name: "Super Admin List" });
          }
        })
        .catch((error) => {
          toast("Error", "AlertTriangleIcon", "danger", "Error Creating User");
        });
    },
    updateUserPassword() {
      this.$refs.passwordRules.validate().then((success) => {
        if (success) {
          this.$store
            .dispatch("user/updateUserPassword", this.password)
            .then((response) => {
              if (response.status_code === 200) {
                toast(
                  "Success",
                  "CheckCircleIcon",
                  "success",
                  "Password Updated Successfully"
                );
                this.$router.push({ name: "Super Admin List" });
              }
            })
            .catch((error) => {
              toast(
                "Error",
                "AlertTriangleIcon",
                "danger",
                "Error Updating Password"
              );
            });
        } else {
          toast(
            "Validation Error",
            "AlertTriangleIcon",
            "danger",
            "Please fill all the required fields"
          );
        }
      });
    },
  },
};
</script>
<style scoped>
.edit-btn {
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);
  color: white;
}

.data-text {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;

  color: #01185e;
}
</style>
